<template>
<div>
    <h1 class="title is-1">Podcast Cambouis créatif</h1>
    <section class='margin-bottom-2em'>
    <h2 class="subtitle is-2">6 - Daniel Wyss</h2>
    <p class="has-text-right">5 juin 2020</p>
    <p>Dans cet épisode je parle avec Daniel Wyss réalisateur et producteur de film documentaires sur cette double casquette, sur son projet à propos de l'agence spatiale congolaise et celui du business des roses pour la St. Valentin.</p>
    <router-link to="/episode/daniel-wyss" class="button is-primary">Allez à l'épisode</router-link>
    </section>
    <section class='margin-bottom-2em'>
    <h2 class="subtitle is-2">5 - Thierry Epiney</h2>
    <p class="has-text-right">22 mai 2020</p>
    <p>Dans cet épisode je parle avec Thierry Epiney compositeur et musicien, de son groupe Tikom, de la composition pour les musique des cirques Starlight et Monti et de la musique de film.</p>
    <router-link to="/episode/thierry-epiney" class="button is-primary">Allez à l'épisode</router-link>
    </section>
    <section class='margin-bottom-2em'>
    <h2 class="subtitle is-2">4 - Adrien Bürki</h2>
    <p class="has-text-right">15 mai 2020</p>
    <p>Dans cet épisode je parle avec Adrien Bürki de son travail d'écrivain, de son roman feuilleton "Couronne boréale" qu'il écrit pendant le confinement et de son receuil de nouvelles "Sur la Chapelle".</p>
    <router-link to="/episode/adrien-burki" class="button is-primary">Allez à l'épisode</router-link>
    </section>
    <section class='margin-bottom-2em'>
    <h2 class="subtitle is-2">3 - Stéphane Paccaud</h2>
    <p class="has-text-right">8 mai 2020</p>
    <p>Dans cet épisode je parle avec Stéphane Paccaud de son travail de musicien, de David Lynch, Lovecraft et de l'écriture de sa pièce de théâtre.</p>
    <router-link to="/episode/stephane-paccaud" class="button is-primary">Allez à l'épisode</router-link>
    </section>

    <section class='margin-bottom-2em'>
    <h2 class="subtitle is-2">2 - Valentin Villard</h2>
    <p class="has-text-right">1 mai 2020</p>
    <p>Dans cet épisode je parle avec Valentin Villard de son travail de compositeur de musique classique, des sonnets de Shakespeare et de la passion qu'il a composée.</p>
    <router-link to="/episode/valentin-villard" class="button is-primary">Allez à l'épisode</router-link>
    </section>
<section class='margin-bottom-2em'>
<h2 class="subtitle is-2">1 - Helen Mc Geachy</h2>
<p class="has-text-right">24 avril 2020</p>
<p>Dans ce premier épisode je parle avec Helen Mc Geachy de son travail d'illustratrice, de ses cours de dessin via twitch et surtout de comment elle adapte un scénario en roman graphique.</p>
<router-link to="/episode/helen-mc-geachy" class="button is-primary">Allez à l'épisode</router-link>
</section>

</div>

</template>

<script>
export default {
  name: 'Home',
  metaInfo: {
      title: 'Cambouis créatif - podcast',
      meta: [
        { name: 'author', content: 'Reto Steffen'},
        { name: 'keywords', content: 'Cambouis creatif, Cambouis créatif, Le cambouis créatif, podcast'},
        { name: 'description', content: 'Cambouis créatif est un podcast dans lequel les artistes parlent de leur projets créatifs et où nous mettons les mains dans le cambouis du travail créatif au jour le jour.'},
        { property: 'og:description', content: 'Cambouis créatif est un podcast dans lequel les artistes parlent de leur projets créatifs et où nous mettons les mains dans le cambouis du travail créatif au jour le jour.'},
        { property:'og:title', content: 'Cambouis créatif - podcast'},
        { property:'og:url', content: 'https://www.cambouiscreatif.ch/'},

      ],
      link: [
        { rel: 'canonical', href: 'https://www.cambouiscreatif.ch/'},
      ]
    }
}
</script>

<style scoped>

</style>
