<template>
  <div id="app">
    <div class='container'>
      <header><Navbar></Navbar></header>
    </div>
    <section class="section">
      <div class='container'>
        <div class="content">
          <div class="columns">
            <div class="column is-three-quarters">
              <router-view></router-view>
            </div>
            <div class="column sidebar"><Sidebar></Sidebar></div>
          </div>
        </div>
      </div>
    </section>
    <footer class='footer'>
      <div class='container'>
        <div class="columns">
          <div class='column'>
            <p>© 2020 cambouiscreatif.ch</p>
          </div>
          <div class='column'>
            <p class="has-text-right"><span><a href='http://eepurl.com/gYE0k5' target="_blank" rel="noopener">Inscrivez vous à la newsletter</a></span>
              <span class='sm-icon sm-icon-first'><a href='https://www.twitter.com/cambouiscreatif' target="_blank" aria-label="Twitter" rel="noopener"><i class="fab fa-twitter"></i></a></span> <span class='sm-icon'><a href='https://www.facebook.com/cambouiscreatif' target="_blank" aria-label="Facebook"  rel="noopener"><i class="fab fa-facebook-square"></i></a></span>
            </p>
          </div></div></div>
        </footer>
      </div>
    </template>

    <script>

    import Navbar from './components/Navbar.vue'
    import Sidebar from './components/Sidebar.vue'

    export default {
      name: 'app',
      components: {
        Navbar,
        Sidebar,
      },
      metaInfo: {
        title: 'Cambouis créatif',
        titleTemplate: '%s | cambouiscreatif.ch',
        htmlAttrs: {
        },
        meta: [
          { name: 'author', content: 'Reto Steffen'},
          { name: 'keywords', content: 'Cambouis creatif, Cambouis créatif, Le cambouis créatif, podcast'},
          { name: 'description', content: 'Cambouis créatif est un podcast dans lequel les artistes parlent de leur projets créatifs et où nous mettons les mains dans le cambouis du travail créatif au jour le jour.'},
        ]
      },

    }





    </script>

    <style>
    #app {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }


    .sm-icon {

      padding-right:1rem;

    }
    .sm-icon-first {
      padding-left:1rem;
    }
    </style>
