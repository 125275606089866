<template>
<div class="content">
<h1 class="title is-1">Episode 4</h1>
<section>
<h2>Conversation avec Adrien Bürki</h2>
<p>Dans cet épisode je parle avec Adrien Bürki de son travail d'écrivain, de son roman feuilleton "la Couronne boréale" qu'il écrit pendant le confinement et de son recueil de nouvelles "Sur la Chapelle" à propos de la chapelle qui veillait sur le village de Saint-Légier.</p>
<div class='margin-bottom-2em'>
<iframe src="https://anchor.fm/cambouiscreatif/embed/episodes/Conversation-avec-Adrien-Brki-ee00v3" height="102px" width="440px" frameborder="0" scrolling="no"></iframe></div>
<p>Pour lire la Couronne boréale, commencez par le premier épisode <a href="https://lacouronneboreale.wordpress.com/2020/03/15/i/" target="_blank" rel="noopener">ici</a>.</p>
<p>Pour plus d'informations sur "Sur la Chapelle" c'est sur le site des <a href="http://www.editions-aire.ch/details.php?id=2076" target="_blank" rel="noopener">editions de l'Aire</a>.</p>

<h3>Les recommandations</h3>
<h4>La recommandation d'Adrien Bürki</h4>
<section class='margin-bottom-2em'>
<p>La chanteuse Sibylle Baier</p>
<iframe src="https://open.spotify.com/embed/album/1lf3rLmrO0ObMieg4YqEuB" width="440" height="520" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
</section>

<h4>Les recommandations de Reto</h4>
<section class='margin-bottom-2em'>
<p>A l'occasion du centenaire de Ravi Shankar des rééditons de concerts et d'émissions radios sur deux albums.</p>
<iframe src="https://open.spotify.com/embed/album/6HpekSTFcuGgJENZ2BR8QT" width="440" height="520" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe></section>
<section class='margin-bottom-2em'>
<iframe src="https://open.spotify.com/embed/album/4FFnVfVBZNNN5VFIq6l98X" width="440" height="520" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
<p>Et la première partie d'un exposé sur les ragas:</p>
<div class='embed-container'><iframe src='https://www.youtube.com/embed/ezeWmFLG9kM' frameborder='0' allowfullscreen></iframe></div>
<p>Et voici les liens vers la <a href="https://youtu.be/twzynMj1qtI" target="_blank" rel="noopener">deuxième</a> et la <a href="https://youtu.be/it9OjiCvycE" target="_blank" rel="noopener">troisième</a> partie.</p>
</section>

<h3>Adrien Bürki sur le web</h3>
<p><a href="https://lacouronneboreale.wordpress.com/2020/03/15/i/" target="_blank" rel="noopener">La Couronne boréale</a>.</p>
</section>

</div>

</template>

<script>
export default {
  name: 'Episode4',
  metaInfo: {
      title: 'Cambouis créatif - Conversation avec Adrien Bürki',
      meta: [
        { name: 'author', content: 'Reto Steffen'},
        { name: 'keywords', content: 'Cambouis creatif, Cambouis créatif, Le cambouis créatif, podcast, Adrien Bürki, Adrien, Bürki, Burki'},
        { name: 'description', content: "Dans cet épisode je parle avec Adrien Bürki de son travail d'écrivain, de son roman feuilleton 'la Couronne boréale' qu'il écrit pendant le confinement et de son recueil de nouvelles 'Sur la Chapelle' à propos de la chapelle qui veillait sur le village de Saint-Légier."},
        { property: 'og:description', content: "Dans cet épisode je parle avec Adrien Bürki de son travail d'écrivain, de son roman feuilleton 'la Couronne boréale' qu'il écrit pendant le confinement et de son recueil de nouvelles 'Sur la Chapelle' à propos de la chapelle qui veillait sur le village de Saint-Légier."},
        { property:'og:title', content: 'Cambouis créatif - Conversation avec Adrien Bürki'},
        { property:'og:url', content: 'https://www.cambouiscreatif.ch/episode/adrien-burki/'},

      ],
      link: [
        { rel: 'canonical', href: 'https://www.cambouiscreatif.ch/episode/adrien-burki/'},
      ]
    }
}
</script>

<style scoped>
.embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }
</style>
