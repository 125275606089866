<template>
<div class="content">
<h1 class="title is-1">Episode 1</h1>
<section>
<h2>Conversation avec Helen Mc Geachy</h2>
<p>Dans ce premier épisode je parle avec Helen Mc Geachy de son travail d'illustratrice, de ses cours de dessin via twitch et surtout de comment elle adapte un scénario en roman graphique.</p>
<div><iframe src="https://anchor.fm/cambouiscreatif/embed/episodes/Conversation-avec-Helen-Mc-Geachy-ed6tgt" height="102px" width="440px" frameborder="0" scrolling="no"></iframe></div>

<h3>Les recommandations</h3>
<h4>Les recommandations d'Helen  Mc Geachy</h4>
<section class='margin-bottom-2em'>
<p>Le podcast "L'arnaque" de Pénélope Boeuf</p>
<iframe src="https://open.spotify.com/embed-podcast/show/79vSLSnjf3shrylW7vICDG" width="440" height="232" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
</section>

<h4>Les recommandations de Reto</h4>
<section class='margin-bottom-2em'>
<p>Le nouvel album de Bongeziwe Mabandla recommandé par Reto</p>
<iframe src="https://open.spotify.com/embed/album/08SJ9H5uec4YtEm2izy1XN" width="440" height="520" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
</section>

<h3>Retrouvez les illustration d'Helen Mc Geachy sur</h3>
<p><a href="https://www.instagram.com/helen_mc-geachy" target="_blank" rel="noopener">Son instagram</a></p>
<p><a href="https://www.facebook.com/mcgeachyh/" target="_blank" rel="noopener">Sa page facebook</a></p>
<p><a href="https://m.twitch.tv/apolline_art/profile" target="_blank" rel="noopener">Ses cours de dessin via twitch</a></p>
</section>

</div>

</template>

<script>
export default {
  name: 'Episode1',
  metaInfo: {
      title: 'Cambouis créatif - Conversation avec Helen Mc Geachy',
      meta: [
        { name: 'author', content: 'Reto Steffen'},
        { name: 'keywords', content: 'Cambouis creatif, Cambouis créatif, Le cambouis créatif, podcast, Helen Mc Geachy,Helen, Mc Geachy, McGeachy'},
        { name: 'description', content: "Dans ce premier épisode je parle avec Helen Mc Geachy de son travail d'illustratrice, de ses cours de dessin via twitch et surtout de comment elle adapte un scénario en roman graphique."},
        { property: 'og:description', content: "Dans ce premier épisode je parle avec Helen Mc Geachy de son travail d'illustratrice, de ses course de dessin via twitch et surtout de comment elle adapte un scénario en roman graphique."},
        { property:'og:title', content: 'Cambouis créatif - Conversation avec Helen Mc Geachy'},
        { property:'og:url', content: 'https://www.cambouiscreatif.ch/episode/helen-mc-geachy/'},

      ],
      link: [
        { rel: 'canonical', href: 'https://www.cambouiscreatif.ch/episode/helen-mc-geachy/'},
      ]
    }
}
</script>

<style scoped>

</style>
