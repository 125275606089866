<template><div>
<section class='margin-bottom-2em'>
  <img v-lazy="require('@/assets/images/cambouiscreatif.jpg')" alt="cambouis créatif logo">
<h2 class="subtitle is-2 has-text-centered sidebar-title">Cambouis créatif</h2>
<p>Cambouis créatif vous propose d'écouter chaque semaine un•e artiste différent•e parler de son travail au jour le jour. On va parler illustration, théâtre, musique, photographie et bien plus.<br>Et nous allons bien évidemment mettre les mains dans le cambouis.</p>
<p>Vous pouvez vous abonner sur:</p>
<ul>
  <li><a href="https://podcasts.apple.com/us/podcast/cambouis-cr%C3%A9atif/id1507991399" target="_blank" rel="noopener"><i class="fab fa-apple"></i> Apple Podcasts</a></li>
  <li><a href="https://open.spotify.com/show/6khJx5JJjOG1OQ1quHQPxD" target="_blank" rel="noopener"><i class="fab fa-spotify"></i> Spotify</a></li>
  <li><a href="https://www.google.com/podcasts?feed=aHR0cHM6Ly9hbmNob3IuZm0vcy8xMzQ4MDljOC9wb2RjYXN0L3Jzcw==" target="_blank" rel="noopener"><i class="fab fa-google"></i> Google Podcasts</a></li>
  <li><a href="https://anchor.fm/cambouiscreatif" target="_blank" rel="noopener"><i class="fas fa-link"></i> Toutes les autres plateformes</a></li>
  <li><a href="https://anchor.fm/s/134809c8/podcast/rss" target="_blank" rel="noopener"><i class="fas fa-rss"></i> Flux RSS</a></li></ul>
</section>
<section>
  <p>Visuel par <a href="https://www.facebook.com/mcgeachyh/" target="_blank" rel="noopener">Helen Mc Geachy</a></p>
  <p>Jingle par <a href="https://soundcloud.com/st-phane-paccaud" target="_blank" rel="noopener">Stéphane Paccaud</a></p>
  <p>Podcast par <a href="https://www.retosteffen.ch" target="_blank" rel="noopener">Reto Steffen</a></p>
</section></div>
</template>

<script>
export default {
  name: 'Sidebar',
}
</script>

<style scoped>

</style>
