import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import VueAnalytics from 'vue-analytics';
import VueLazyload from 'vue-lazyload';
import Meta from 'vue-meta'

Vue.config.productionTip = false

Vue.use(VueRouter);
Vue.use(VueLazyload, {
});
Vue.use(Meta);


import Home from './components/Home.vue';
import Contact from './components/Contact.vue';
import Episode1 from './components/Episode1.vue';
import Episode2 from './components/Episode2.vue';
import Episode3 from './components/Episode3.vue';
import Episode4 from './components/Episode4.vue';
import Episode5 from './components/Episode5.vue';
import Episode6 from './components/Episode6.vue';



const routes = [
  { path: '/', component: Home },
  { path: '/contact', component: Contact },
  { path: '/episode/helen-mc-geachy', component: Episode1 },
  { path: '/episode/valentin-villard', component: Episode2 },
  { path: '/episode/stephane-paccaud', component: Episode3 },
  { path: '/episode/adrien-burki', component: Episode4 },
  { path: '/episode/thierry-epiney', component: Episode5 },
  { path: '/episode/daniel-wyss', component: Episode6 },
]

const router = new VueRouter({
  mode: 'history',
base: __dirname,
  routes
})

Vue.use(VueAnalytics, {
id: 'UA-162690864-1',
  router,
  debug: {
    sendHitTask: process.env.NODE_ENV === 'production'
  }
});

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')


const prod = process.env.NODE_ENV === 'production'
const shouldSW = 'serviceWorker' in navigator && prod
if (shouldSW) {
  navigator.serviceWorker.register('service-worker.js').then(() => {
    //console.log("Service Worker Registered!")
  })
}
