<template>
<div class="content">
<h1 class="title is-1">Episode 5</h1>
<section>
<h2>Conversation avec Thierry Epiney</h2>
<p>Dans cet épisode je parle avec Thierry Epiney compositeur et musicien, de son groupe Tikom, de la composition de musique pour les cirques Starlight et Monti et de la musique de film.</p>
<div class='margin-bottom-2em'>
<iframe src="https://anchor.fm/cambouiscreatif/embed/episodes/Conversation-avec-Thierry-Epiney-eec5fl" height="102px" width="440px" frameborder="0" scrolling="no"></iframe></div>
<div class='margin-bottom-2em'>
<p>Le teaser de Tikom</p>
<div class='embed-container'><iframe src='https://www.youtube.com/embed/tpsTOWIqfkM' frameborder='0' allowfullscreen></iframe></div>
</div>
<div class='margin-bottom-2em'>
<p>Un trailer du Cirque Monti avec la musique de Thierry</p>
<div class='embed-container'><iframe src='https://www.youtube.com/embed/A8gLBW2pqk4' frameborder='0' allowfullscreen></iframe></div></div>
<p>La musique du cirque Starlight</p>
<p><iframe width="440" height="450" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/99754421&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>
</p>

<h3>Les recommandations</h3>
<h4>La recommandation de Thierry Epiney</h4>
<section class='margin-bottom-2em'>
<p>L'artiste Meimuna</p>
<p>Les oiseaux de Paradis</p>
<iframe src="https://open.spotify.com/embed/track/0OncK9lK4lsT1ggt7j6ByA" width="440" height="80" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
<p>La tristesse du diable</p>
<iframe src="https://open.spotify.com/embed/track/2Wm9L8AVKOjGpwI3rb6sZF" width="440" height="80" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>

</section>

<h4>Les recommandations de Reto</h4>
<section class='margin-bottom-2em'>
<p>Le nouvel album de David Krakauer et Kathleen Tagg "Breath &amp; Hammer"</p>
<iframe style="border: 0; width: 440px; height: 120px;" src="https://bandcamp.com/EmbeddedPlayer/album=1133610750/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/artwork=small/transparent=true/" seamless><a href="http://krakauertagg.bandcamp.com/album/breath-hammer">Breath &amp; Hammer by David Krakauer &amp; Kathleen Tagg</a></iframe>
</section>
<section class='margin-bottom-2em'>
  <p>Et l'album "Together we stand" de Abraham Inc.</p>
<iframe src="https://open.spotify.com/embed/album/1RoH1aflxsplP7spF3g6Fq" width="440" height="380" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
</section>


<h3>Thierry Epiney sur le web</h3>
<p><a href="http://www.thierryepiney.ch/public/" target="_blank" rel="noopener">Le site de Thierry</a>.</p>
<p><a href="https://www.facebook.com/tikom.music/" target="_blank" rel="noopener">La page Facebook de Tikom</a>.</p>
<p><a href="https://soundcloud.com/tikom" target="_blank" rel="noopener">La page SoundCloud de Tikom</a>.</p>
</section>

</div>

</template>

<script>
export default {
  name: 'Episode5',
  metaInfo: {
      title: 'Cambouis créatif - Conversation avec Thierry Epiney',
      meta: [
        { name: 'author', content: 'Reto Steffen'},
        { name: 'keywords', content: 'Cambouis creatif, Cambouis créatif, Le cambouis créatif, podcast, Thierry Epiney, Thierry, Epiney, cirque, Monti, Starlight'},
        { name: 'description', content: "Dans cet épisode je parle avec Thierry Epiney compositeur et musicien, de son groupe Tikom, de la composition de musique pour les cirques Starlight et Monti et de la musique de film."},
        { property: 'og:description', content: "Dans cet épisode je parle avec Thierry Epiney compositeur et musicien, de son groupe Tikom, de la composition de musique pour les cirques Starlight et Monti et de la musique de film."},
        { property:'og:title', content: 'Cambouis créatif - Conversation avec Thierry Epiney'},
        { property:'og:url', content: 'https://www.cambouiscreatif.ch/episode/thierry-epiney/'},

      ],
      link: [
        { rel: 'canonical', href: 'https://www.cambouiscreatif.ch/episode/thierry-epiney/'},
      ]
    }
}
</script>

<style scoped>
.embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }
</style>
