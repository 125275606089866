<template>
<div class="content">
<h1 class="title is-1">Episode 3</h1>
<section>
<h2>Conversation avec Stéphane Paccaud</h2>
<p>Dans cet épisode je parle avec Stéphane Paccaud de son travail de musicien, de David Lynch, Lovecraft et de l'écriture de sa pièce de théâtre.</p>
<div class='margin-bottom-2em'>
<iframe src="https://anchor.fm/cambouiscreatif/embed/episodes/Conversation-avec-Stphane-Paccaud-ednr9h" height="102px" width="440px" frameborder="0" scrolling="no"></iframe></div>
<p>Pour écouter les titres de Lady in Red c'est sur <a href="https://mx3.ch/ladyinred" target="_blank" rel="noopener">mx3</a></p>
<p>Vous pouvez retrouver les compositions de Stéphane sur son Soundcloud</p>
<p><iframe width="440" height="450" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/users/118879345&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>
</p>

<h3>Les recommandations</h3>
<h4>Les recommandations de Stéphane Paccaud</h4>
<section class='margin-bottom-2em'>
<p>Le théâtre des <a href="https://troispetitstours.ch/" target="_blank" rel="noopener">"Trois P'Tits Tours"</a> à Morges et son nouveau comité.</p>
</section>
<section class='margin-bottom-2em'>
<p>Le <a href="https://troispetitstours.ch/" target="_blank" rel="noopener">théâtre de la Ruelle</a> à la Chaux sur Cossonay et son  <a href="https://www.comsi.ch" target="_blank" rel="noopener">festival des Granges</a>.</p>
</section>
<h4>Les recommandations de Reto</h4>
<section class='margin-bottom-2em'>
<p>L'album "What Kinda Music" de Tom Misch et Yussef Dayes</p>
<iframe src="https://open.spotify.com/embed/album/6iOCv7oGL5sGi2aVnRz2BI" width="440" height="520" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
</section>
<section class='margin-bottom-2em'>
<p>L'album "Celebration" de Thierry Lang et Heiri Känzig</p>
<iframe src="https://open.spotify.com/embed/album/7DOQRk19rbVyHcfpw1B6Ug" width="440" height="520" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
</section>

<h3>Stéphane Paccaud sur le web</h3>
<p><a href="https://soundcloud.com/st-phane-paccaud" target="_blank" rel="noopener">Son site soundcloud</a></p>
</section>

</div>

</template>

<script>
export default {
  name: 'Episode3',
  metaInfo: {
      title: 'Cambouis créatif - Conversation avec Stéphane Paccaud',
      meta: [
        { name: 'author', content: 'Reto Steffen'},
        { name: 'keywords', content: 'Cambouis creatif, Cambouis créatif, Le cambouis créatif, podcast, Stéphane Paccaud, Stéphane, Paccaud, Lynch, David Lynch, Lovecraft'},
        { name: 'description', content: "Dans cet épisode je parle avec Stéphane Paccaud de son travail de musicien, de David Lynch, Lovecraft et de l'écriture de sa pièce de théâtre."},
        { property: 'og:description', content: "Dans cet épisode je parle avec Stéphane Paccaud de son travail de musicien, de David Lynch, Lovecraft et de l'écriture de sa pièce de théâtre."},
        { property:'og:title', content: 'Cambouis créatif - Conversation avec Stéphane Paccaud'},
        { property:'og:url', content: 'https://www.cambouiscreatif.ch/episode/stephane-paccaud/'},

      ],
      link: [
        { rel: 'canonical', href: 'https://www.cambouiscreatif.ch/episode/stephane-paccaud/'},
      ]
    }
}
</script>

<style scoped>

</style>
