<template>
<div class="content">
<h1 class="title is-1">Episode 6</h1>
<section>
<h2>Conversation avec Daniel Wyss</h2>
<p>Dans cet épisode je parle avec Daniel Wyss réalisateur et producteur de film documentaires sur cette double casquette, sur son projet à propos de l'agence spatiale congolaise et celui du business des roses pour la St. Valentin.</p>
<div class='margin-bottom-2em'>
<iframe src="https://anchor.fm/cambouiscreatif/embed/episodes/Conversation-avec-Daniel-Wyss-ef07f2" height="102px" width="440px" frameborder="0" scrolling="no"></iframe></div>


<h3>Les recommandations</h3>
<h4>La recommandation de Daniel Wyss</h4>
<section class='margin-bottom-2em'>
<p>Le film Hedwig and the Angry Inch</p>
<div class='embed-container'><iframe src='https://www.youtube.com/embed/4p9mPhGo1j0' frameborder='0' allowfullscreen></iframe></div>
<p>La page <a href="https://www.imdb.com/title/tt0248845/" target="_blank" rel="noopener">IMDB</a> du film</p>
</section>

<h4>Les recommandations de Reto</h4>
<section class='margin-bottom-2em'>
<p>Le nouvel album de Pax Instrumentals du Youngblood Brass Band.</p>
<iframe src="https://open.spotify.com/embed/album/3qX1pAxwj3EWnZVIYbKteG" width="440" height="380" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>

</section>



<h3>Daniel Wyss sur le web</h3>
<p><a href="https://www.rts.ch/dossiers/climage/" target="_blank" rel="noopener">Les films de la société Climage sur le site de la RTS</a>.</p>
<p><a href="https://climage.ch" target="_blank" rel="noopener">Le site de Climage</a>.</p>

</section>

</div>

</template>

<script>
export default {
  name: 'Episode6',
  metaInfo: {
      title: 'Cambouis créatif - Conversation avec Daniel Wyss',
      meta: [
        { name: 'author', content: 'Reto Steffen'},
        { name: 'keywords', content: 'Cambouis creatif, Cambouis créatif, Le cambouis créatif, podcast, Daniel Wyss, Daniel, Wyss, documentaire, au nom des roses, climage, réalisateur, producteur, Troposphère, congo, fusée, Valentin, Jean-Patrice Keka, Keka'},
        { name: 'description', content: "Dans cet épisode je parle avec Daniel Wyss réalisateur et producteur de film documentaires sur cette double casquette, sur son projet à propos de l'agence spatiale congolaise et celui du business des roses pour la St. Valentin."},
        { property: 'og:description', content: "Dans cet épisode je parle avec Daniel Wyss réalisateur et producteur de film documentaires sur cette double casquette, sur son projet à propos de l'agence spatiale congolaise et celui du business des roses pour la St. Valentin."},
        { property:'og:title', content: 'Cambouis créatif - Conversation avec Daniel Wyss'},
        { property:'og:url', content: 'https://www.cambouiscreatif.ch/episode/daniel-wyss/'},

      ],
      link: [
        { rel: 'canonical', href: 'https://www.cambouiscreatif.ch/episode/daniel-wyss/'},
      ]
    }
}
</script>

<style scoped>
.embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }
</style>
