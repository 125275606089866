<template>
<div class="content">
<h1 class="title is-1">Contact</h1>
<div>
  <p>Envoyez-nous vos remarques ou questions à <a href="mailto:info@cambouiscreatif.ch">info@cambouiscreatif.ch</a></p>
  <p>Ou sur <a href="https://www.facebook.com/cambouiscreatif" target="_blank" rel="noopener">Facebook</a> ou sur <a href="https://www.twitter.com/cambouiscreatif" target="_blank" rel="noopener">twitter</a></p>
</div>
</div>

</template>
<script>
export default {
  name: 'Contact',
  metaInfo: {
      title: 'Contact',
      meta: [
        { name: 'author', content: 'Cambouis créatif'},
        { name: 'keywords', content: 'Cambouis creatif, Cambouis créatif, Le cambouis créatif, podcast, Contact'},
        { name: 'description', content: "Contactez l'équipe du podcast cambouis créatif."},
      ],
      link: [
        { rel: 'canonical', href: 'https://www.cambouiscreatif.ch/contact'},
      ]
    },
  data() {
    return {

    }
  },
  methods: {
  }

}
</script>

<style scoped>

</style>
