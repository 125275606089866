<template>
<div class="content">
<h1 class="title is-1">Episode 2</h1>
<section>
<h2>Conversation avec Valentin Villard</h2>
<p>Dans cet épisode je parle avec Valentin Villard de son travail de compositeur de musique classique, des sonnets de Shakespeare et de la passion qu'il a composée.</p>
<div><iframe src="https://anchor.fm/cambouiscreatif/embed/episodes/Conversation-avec-Valentin-Villard-edga3i" height="102px" width="440px" frameborder="0" scrolling="no"></iframe></div>
<p>Vous pouvez retrouver les compositions de Valentin sur <a href="https://www.youtube.com/playlist?list=PL753ED26927DF6501" target="_blank" rel="noopener">Youtube</a> et sur son Soundcloud</p>
<p>
  <iframe width="440" height="450" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/users/4166372&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>
</p>

  <p>Et sur les disques suivants:</p>
<p>
<iframe src="https://open.spotify.com/embed/track/4ApV1uOuWv23lyUC91orL9" width="440" height="80" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
</p>
<p>
<iframe src="https://open.spotify.com/embed/track/18BdMvSWcREMB7c2SH2cSR" width="440" height="80" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
</p>
<p>
<iframe src="https://open.spotify.com/embed/track/4XMji5CXXsVPxO1ffhkDKb" width="440" height="80" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
</p>
<h3>Les recommandations</h3>
<h4>Les recommandations de Valentin Villard</h4>
<section class='margin-bottom-2em'>
<p>L'album "Poème de la veille" de Stéphane Blok</p>
<iframe style="border: 0; width: 440px; height: 120px;" src="https://bandcamp.com/EmbeddedPlayer/album=2352570457/size=large/bgcol=333333/linkcol=e32c14/tracklist=false/artwork=small/transparent=true/" seamless><a href="http://stephaneblok.bandcamp.com/album/po-mes-de-la-veille-2020">Poèmes de la veille / 2020 by Stéphane Blok</a></iframe><br>
</section>
<section class='margin-bottom-2em'>
<p>La chanteuse Aurélie Emery</p>
<iframe src="https://open.spotify.com/embed/album/5X5nVbBZqq31B4kDDv5dIG" width="440" height="520" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
</section>
<h4>Les recommandations de Reto</h4>
<section class='margin-bottom-2em'>
<p>Le nouvel album d'Avishai Cohen</p>
<iframe src="https://open.spotify.com/embed/album/7CT6vBbUdXLtlqOYWNYaXF" width="440" height="520" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
</section>

<h3>Valentin Villard sur le web</h3>
<p><a href="https://www.valentinvillard.com" target="_blank" rel="noopener">Son site</a></p>
</section>

</div>

</template>

<script>
export default {
  name: 'Episode2',
  metaInfo: {
      title: 'Cambouis créatif - Conversation avec Valentin Villard',
      meta: [
        { name: 'author', content: 'Reto Steffen'},
        { name: 'keywords', content: 'Cambouis creatif, Cambouis créatif, Le cambouis créatif, podcast, Valentin Villard, Valentin, Villard'},
        { name: 'description', content: "Dans cet épisode je parle avec Valentin Villard de son travail de compositeur de musique classique, des sonnets de Shakespeare et de la passion qu'il a composée."},
        { property: 'og:description', content: "Dans cet épisode je parle avec Valentin Villard de son travail de compositeur de musique classique, des sonnets de Shakespeare et de la passion qu'il a composée."},
        { property:'og:title', content: 'Cambouis créatif - Conversation avec Valentin Villard'},
        { property:'og:url', content: 'https://www.cambouiscreatif.ch/episode/valentin-villard/'},

      ],
      link: [
        { rel: 'canonical', href: 'https://www.cambouiscreatif.ch/episode/valentin-villard/'},
      ]
    }
}
</script>

<style scoped>

</style>
